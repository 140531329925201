import { cn } from "../../utils/cn";
import { cva, type VariantProps } from "class-variance-authority";

const brandSquareVariants = cva("size-[10px]", {
  variants: {
    color: {
      "brand-1": "bg-brand-1",
      "brand-2": "bg-brand-2",
      "brand-3": "bg-brand-3",
      "brand-4": "bg-brand-4",
      "brand-5": "bg-brand-5",
      "chains-primary": "bg-chains-primary",
      "chains-secondary": "bg-chains-secondary",
      "chains-tertiary": "bg-chains-tertiary",
      "analysts-primary": "bg-analysts-primary",
      "analysts-secondary": "bg-analysts-secondary",
      "analysts-tertiary": "bg-analysts-tertiary",
      "questors-primary": "bg-questors-primary",
      "questors-secondary": "bg-questors-secondary",
      "questors-tertiary": "bg-questors-tertiary",
    },
  },
  defaultVariants: {
    color: "brand-1",
  },
});

interface BrandSquareProps extends VariantProps<typeof brandSquareVariants> {
  className?: string;
}

export type BrandSquareColor = NonNullable<VariantProps<typeof brandSquareVariants>["color"]>;

export const BrandSquare = ({ color, className }: BrandSquareProps) => {
  return <div className={cn(brandSquareVariants({ color }), className)} />;
};
