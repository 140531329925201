import { Root, Item, Trigger, Content, Header } from "@radix-ui/react-accordion";
import { MinusIcon, PlusIcon } from "lucide-react";
import { cn } from "../../utils/cn";
import { forwardRef } from "react";

const Accordion = Root;

const AccordionItem = forwardRef<React.ElementRef<typeof Item>, React.ComponentPropsWithoutRef<typeof Item>>(
  ({ className, ...props }, ref) => (
    <Item ref={ref} className={cn("border-b border-border-secondary", className)} {...props} />
  ),
);
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = forwardRef<React.ElementRef<typeof Trigger>, React.ComponentPropsWithoutRef<typeof Trigger>>(
  ({ className, children, ...props }, ref) => (
    <Header className="flex">
      <Trigger
        ref={ref}
        className={cn(
          "flex flex-1 text-text-primary items-center text-left justify-between py-4 fsc-body-lg transition-all hover:underline",
          "[&[data-state=open]>.plus-icon]:hidden",
          "[&[data-state=open]>.minus-icon]:block",
          className,
        )}
        {...props}
      >
        {children}
        <PlusIcon className="plus-icon h-4 w-4 shrink-0 transition-transform duration-200" />
        <MinusIcon className="minus-icon h-4 w-4 shrink-0 transition-transform duration-200 hidden" />
      </Trigger>
    </Header>
  ),
);
AccordionTrigger.displayName = Trigger.displayName;

const AccordionContent = forwardRef<React.ElementRef<typeof Content>, React.ComponentPropsWithoutRef<typeof Content>>(
  ({ className, children, ...props }, ref) => (
    <Content
      ref={ref}
      className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      {...props}
    >
      <div className={cn("pb-4 pt-0", className)}>{children}</div>
    </Content>
  ),
);

AccordionContent.displayName = Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
