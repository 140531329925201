import type { QuestWithMeta, RewardSpecWithToken } from "@fscrypto/domain/earn";
import type { Token } from "@fscrypto/domain/token";
import { Link, useNavigate } from "@remix-run/react";
import type { Decimal as DecimalType } from "decimal.js";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fscrypto/flip-kit/table";
import { trpc } from "~/utils/trpc";
import { Button } from "~/components/ui/button";
import { getRewardsRemaining, ProgressRewardsRemaining } from "./progress-rewards-remaining";
import { ArrowDown, ChevronRight } from "lucide-react";
import { Text } from "@fscrypto/flip-kit/text";
import { useEffect, useState } from "react";
import { PartnerIcon } from "./partner-icon";

type TableFilters = { partnerSlug?: string };

const usePaginatedQuestTable = ({
  initialQuests,
  filters,
}: { initialQuests: QuestWithMeta[]; filters?: TableFilters }) => {
  const [quests, setQuests] = useState(initialQuests);

  // if the inputted quests change, reset
  useEffect(() => {
    setQuests(initialQuests);
  }, [initialQuests]);

  const { data: nextPageQuests, isLoading: isLoadingNextPage } = trpc.public.quests.all.useQuery(
    { cursor: quests[quests.length - 1]?.id, partner: filters?.partnerSlug },
    { enabled: quests.length > 0 },
  );

  const hasNextPage = nextPageQuests && nextPageQuests.length > 0;

  const loadMore = async () => {
    if (nextPageQuests) setQuests([...quests, ...nextPageQuests]);
  };

  return {
    quests,
    isLoadingNextPage,
    hasNextPage,
    loadMore,
  };
};

export const PaginatedQuestTable = ({
  quests: initialQuests,
  filters,
  theme,
}: { quests: QuestWithMeta[]; filters?: TableFilters; theme?: "default" | "dark" }) => {
  const { quests, isLoadingNextPage, hasNextPage, loadMore } = usePaginatedQuestTable({ initialQuests, filters });

  if (quests.length === 0)
    return (
      <Text variant="body-lg" className="block py-9 text-center">
        No quests available
      </Text>
    );

  return (
    <>
      <QuestTable quests={quests} theme={theme} />
      {hasNextPage && (
        <Button
          className="flex flex-row justify-between w-full"
          size="lg"
          disabled={isLoadingNextPage}
          onClick={loadMore}
        >
          Load More
          <ArrowDown />
        </Button>
      )}
    </>
  );
};

export const QuestTable = ({ quests, theme }: { quests: QuestWithMeta[]; theme?: "default" | "dark" }) => {
  return (
    <>
      <div className="block md:hidden">
        <QuestTableMobile quests={quests} />
      </div>
      <div className="md:block hidden">
        <QuestTableDesktop quests={quests} theme={theme} />
      </div>
    </>
  );
};

export const QuestTableMobile = ({ quests }: { quests: QuestWithMeta[] }) => {
  const navigate = useNavigate();
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-left">Quest</TableHead>
          <TableHead>Max Reward</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {quests.map((quest) => {
          const earnings = parseEarningsString(quest.rewardSpecs);
          return (
            <TableRow
              key={quest.id}
              className="cursor-pointer py-3"
              onClick={() => {
                navigate(`/quest/${quest.slug}`);
              }}
            >
              <TableCell className="text-left flex flex-row items-center">
                <PartnerIcon partner={quest.partner} className="size-3" />
                <Link to={`/quest/${quest.slug}`}>
                  <Text variant="body-lg">{quest.title}</Text>
                </Link>
              </TableCell>
              <TableCell>
                <Text className="text-questors-primary mr-2">{earnings.text}</Text>
                {earnings.token && <Text className="text-text-primary">{earnings.token.symbol}</Text>}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const QuestTableDesktop = ({ quests, theme }: { quests: QuestWithMeta[]; theme?: "default" | "dark" }) => {
  const navigate = useNavigate();
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-left">Quest</TableHead>
          <TableHead>Participants</TableHead>
          <TableHead>Rewards Left</TableHead>
          <TableHead>Max Reward</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {quests.map((quest) => {
          const earnings = parseEarningsString(quest.rewardSpecs);
          const { remaining, total } = getRewardsRemaining(quest);
          return (
            <TableRow
              key={quest.id}
              className="cursor-pointer py-3"
              onClick={() => {
                navigate(`/quest/${quest.slug}`);
              }}
            >
              <TableCell className="text-left flex flex-row items-center">
                <PartnerIcon partner={quest.partner} className="size-9" />
                <Link to={`/quest/${quest.slug}`}>
                  <Text variant="body-lg">{quest.title}</Text>
                </Link>
              </TableCell>
              <TableCell className="text-text-tertiary">{quest.entryCount}</TableCell>
              <TableCell className="min-w-[150px]">
                <ProgressRewardsRemaining theme={theme} remaining={remaining} total={total} />
              </TableCell>
              <TableCell>
                <Text className="text-questors-primary mr-2">{earnings.text}</Text>
                {earnings.token && <Text className="text-text-primary">{earnings.token.symbol}</Text>}
              </TableCell>
              <TableCell>
                <ChevronRight className="stroke-text-primary" />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const parseEarningsString = (rewardSpecs: RewardSpecWithToken[]): { text: string; token: Token | null } => {
  let max: DecimalType | null = null;
  let fixedToken: Token | null = null;
  for (const spec of rewardSpecs) {
    if (spec.tokenFixedAmount && (fixedToken === null || fixedToken.id === spec.token.id)) {
      fixedToken = spec.token;
      if (!max || spec.tokenFixedAmount?.greaterThan(max)) max = spec.tokenFixedAmount;
    }

    if (spec.tokenPercentMultiplier) {
      if (spec.tokenPercentMaxAmount) {
        return { text: spec.tokenPercentMaxAmount.toString(), token: spec.token };
      }
      return { text: `${(spec.tokenPercentMultiplier * 100).toString()}%`, token: spec.token };
    }
  }

  if (max) {
    return { text: max.toString(), token: fixedToken };
  }

  return { text: "-", token: null };
};
