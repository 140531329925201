import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/flip-kit/accordion";
import { BrandSquare, type BrandSquareColor } from "@fscrypto/flip-kit/brand-square";
import { Text } from "@fscrypto/flip-kit/text";
import { cn } from "~/utils/cn";
import { Button } from "./ui/button";

interface FaqSectionProps {
  theme: "light" | "dark";
  dotColor?: BrandSquareColor;
}

export const Faq = () => {
  return <FaqSection theme="dark" dotColor="questors-primary" />;
};

const FaqSection = ({ theme, dotColor }: FaqSectionProps) => {
  return (
    <div
      className={cn("flex flex-col lg:flex-row justify-between gap-4 py-10", {
        dark: theme === "dark",
        light: theme === "light",
      })}
    >
      <div>
        <div className="flex items-center gap-2">
          <BrandSquare color={dotColor ?? "brand-1"} />
          <Text>Frequently asked questions</Text>
        </div>
      </div>

      <div className="lg:basis-1/2">
        <FaqAccordion />
      </div>
    </div>
  );
};

const FaqAccordion = () => (
  <Accordion type="single" collapsible>
    <AccordionItem key="0" value="0">
      <AccordionTrigger>Why should I participate in Flipside Quests?</AccordionTrigger>
      <AccordionContent className="text-text-tertiary">
        Flipside Quests are a gamified onchain experience designed to encourage users to explore, learn, and engage with
        various blockchain ecosystems while earning rewards. By participating in Quests, users can increase their
        onchain scores and increase their rewards.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem key="1" value="1">
      <AccordionTrigger>What are onchain scores?</AccordionTrigger>
      <AccordionContent className="text-text-tertiary">
        <span>
          Users connect their web3 wallets to participate in Quests. Once connected, you will see your onchain score for
          that ecosystem. Scores are based on your onchain activity in that ecosystem within the last 90 days, scored
          from 0-15.{" "}
          <Button variant="link" className="p-0" asChild>
            <a
              href="https://science.flipsidecrypto.xyz/user-scores-math/flipside_user_scores_math.html"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </Button>{" "}
          about how scores work.
        </span>
      </AccordionContent>
    </AccordionItem>

    <AccordionItem key="2" value="2">
      <AccordionTrigger>How do scores impact my potential rewards?</AccordionTrigger>
      <AccordionContent className="text-text-tertiary">
        By completing Flipside Quests, or transacting onchain generally, users can improve their onchain scores.
        Improving your score increases your potential rewards and also expands your eligibility to participate in
        score-gated Quests, which target an ecosystem’s most loyal users.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem key="3" value="3">
      <AccordionTrigger>How long does it take Quests to verify before I can claim rewards?</AccordionTrigger>
      <AccordionContent className="text-text-tertiary">
        Quest verification times vary depending on the chain a Quest is targeting and Flipside’s data latency for that
        chain. View our{" "}
        <Button variant="link" className="p-0" asChild>
          <a
            href="https://docs.flipsidecrypto.xyz/data/flipside-data/table-freshness-targets"
            target="_blank"
            rel="noreferrer"
          >
            data freshness targets
          </a>
        </Button>{" "}
        to see how often data and Quest verifications refresh for each chain.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem key="4" value="4">
      <AccordionTrigger>My Quest verification failed, what should I do?</AccordionTrigger>
      <AccordionContent className="text-text-tertiary">
        If you completed a Quest and it failed to verify, you should double check that you completed all the required
        steps with the minimum token(s) required. If you missed something, you can redo the steps and try to verify the
        Quest again. If the issue persists,{" "}
        <Button variant="link" className="p-0" asChild>
          <a href="https://docs.flipsidecrypto.xyz/support/open-a-ticket" target="_blank" rel="noreferrer">
            reach out to support via the app
          </a>
        </Button>
        .
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);
